.sidebar {
    background-color: #636f83;
}

.sidebarLink {
    @apply w-full px-5;
    display: flex;
    color: rgba(255, 255, 255, 0.6);
    transition: background .15s ease-in-out;
    background-color: #636f83;
}
.sidebarLink:hover {
    background-color: #2B3347;
}
.sidebarLink > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sidebarLink > div:first-child {
    flex: 1;
}

.sidebarLinkActive {
    @apply sidebarLink font-bold;
    background-color: #2B3347;
}

@media screen and (max-width: 1023px) {
    .sidebar {
        @apply block fixed inset-0 z-10 py-5;
        overflow-y: auto;
    }

    .searchAndToggle {
        @apply flex justify-end mb-5;
    }

    .search {
        @apply flex-1 px-5;
    }
    .search input[type=search] {
        @apply w-full rounded;
        @apply px-3 py-2;
    }

    .sidebarToggle {
        @apply flex justify-end pr-5;
    }

    .sidebarLink {
        @apply py-4;
    }

    .logo {
        @apply hidden;
    }
}

@media screen and (min-width: 1024px) {
    .sidebar {
        width: 240px;
        @apply block !important;
        @apply min-h-screen;
    }

    .searchAndToggle {
        @apply hidden;
    }

    .sidebarLink {
        @apply py-2;
    }

    .logo {
        @apply px-3 pt-4 pb-3 mb-2;
        background-color: #2B3347;
    }

    .title {
        @apply text-lg text-right font-medium;
        color: rgba(255, 255, 255, 0.6);
    }
}