@use "variables" as *
@forward "variables"

@mixin borderRadius
  border-radius: 4px
@mixin borderRadius-top-left
  border-top-left-radius: 4px
@mixin borderRadius-top-right
  border-top-right-radius: 4px
@mixin borderRadius-bottom-left
  border-bottom-left-radius: 4px
@mixin borderRadius-bottom-right
  border-bottom-right-radius: 4px
@mixin borderRadius-left
  border-top-left-radius: 4px
  border-bottom-left-radius: 4px
@mixin borderRadius-right
  border-top-right-radius: 4px
  border-bottom-right-radius: 4px
@mixin borderRadius-bottom
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px

@mixin fontSize12
  font-size: $font-size-12
@mixin fontSize14
  font-size: $font-size-14
@mixin fontSize16
  font-size: $font-size-16
@mixin fontSize24
  font-size: $font-size-24

@mixin fontWeightMedium
  font-weight: 500
@mixin fontWeightBold
  font-weight: 700

@mixin grid
  display: grid
  gap: $padding
@mixin gridTwoColumns
  @include grid
  grid-template-columns: repeat(2, minmax(0, 1fr))
@mixin gridThreeColumns
  @include grid
  grid-template-columns: repeat(3, minmax(0, 1fr))
@mixin gridFourColumns
  @include grid
  grid-template-columns: repeat(4, minmax(0, 1fr))

@mixin justifyVerticalCenter
  display: flex
  flex-direction: column
  justify-content: center

@mixin list
  @include padding-left

  li
    @include margin-bottom-1_4
    list-style: disc

@mixin margin-top
  margin-top: $margin
@mixin margin-left
  margin-left: $margin
@mixin margin-right
  margin-right: $margin
@mixin margin-bottom
  margin-bottom: $margin
@mixin margin-top-3_4
  margin-top: $margin-3_4
@mixin margin-left-3_4
  margin-left: $margin-3_4
@mixin margin-right-3_4
  margin-right: $margin-3_4
@mixin margin-bottom-3_4
  margin-bottom: $margin-3_4
@mixin margin-top-1_2
  margin-top: $margin-1_2
@mixin margin-left-1_2
  margin-left: $margin-1_2
@mixin margin-right-1_2
  margin-right: $margin-1_2
@mixin margin-bottom-1_2
  margin-bottom: $margin-1_2
@mixin margin-top-1_4
  margin-top: $margin-1_4
@mixin margin-left-1_4
  margin-left: $margin-1_4
@mixin margin-right-1_4
  margin-right: $margin-1_4
@mixin margin-bottom-1_4
  margin-bottom: $margin-1_4
@mixin margin-top-1-1_2
  margin-top: 1.5 * $margin
@mixin margin-bottom-1-1_2
  margin-bottom: 1.5 * $margin

@mixin padding
  padding: $padding
@mixin padding-top
  padding-top: $padding
@mixin padding-left
  padding-left: $padding
@mixin padding-right
  padding-right: $padding
@mixin padding-bottom
  padding-bottom: $padding
@mixin padding-3_4
  padding: $padding-3_4
@mixin padding-top-3_4
  padding-top: $padding-3_4
@mixin padding-left-3_4
  padding-left: $padding-3_4
@mixin padding-right-3_4
  padding-right: $padding-3_4
@mixin padding-bottom-3_4
  padding-bottom: $padding-3_4
@mixin padding-1_2
  padding: $padding-1_2
@mixin padding-top-1_2
  padding-top: $padding-1_2
@mixin padding-left-1_2
  padding-left: $padding-1_2
@mixin padding-right-1_2
  padding-right: $padding-1_2
@mixin padding-bottom-1_2
  padding-bottom: $padding-1_2
@mixin padding-1_4
  padding: $padding-1_4
@mixin padding-top-1_4
  padding-top: $padding-1_4
@mixin padding-left-1_4
  padding-left: $padding-1_4
@mixin padding-right-1_4
  padding-right: $padding-1_4
@mixin padding-bottom-1_4
  padding-bottom: $padding-1_4