@use "../../mixins" as *

.middle
  @include padding-top

.sortAndFilter
  display: flex

  > div:last-child
    flex: 1

  button,
  input,
  select
    @include borderRadius
    width: 100%

  select
    padding: 5px

  svg
    width: 20px
    height: 20px

.part
  @include borderRadius
  display: flex
  height: 46px
  background-color: $gray-100

.spacer
  @include padding-left-1_2