@use "mixins" as *

@tailwind base
@tailwind components
@tailwind utilities

body
  @include fontSize16
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  color: rgba(44, 56, 74, 0.95)
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.amplify-button--primary
  border-width: var(--amplify-components-button-primary-border-width) !important
  background-color: var(--amplify-components-button-primary-background-color) !important
  border-color: var(--amplify-components-button-primary-border-color) !important
  color: var(--amplify-components-button-primary-color) !important

  &:hover
    background-color: var(--amplify-components-button-primary-hover-background-color) !important
    border-color: var(--amplify-components-button-primary-hover-border-color) !important
    color: var(--amplify-components-button-primary-hover-color) !important