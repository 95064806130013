@use "../../mixins" as *

.box
  margin: $margin-1_2 0
  padding: $padding-1_2
  border: 2px solid $gray-300
  border-radius: 6px

.boxTitle
  display: flex
  margin-bottom: $margin-3_4

  > div:first-child
    display: flex
    flex-direction: column
    justify-content: center
    flex: 1

  strong
    display: block

.selectSource
  label,
  input[type=radio]
    cursor: pointer

  input[type=radio]
    margin-right: 6px

.add,
.delete
  display: flex
  justify-content: flex-end

  button
    font-size: 14px
    color: $gray-500
    padding: $padding-1_4 $margin-1_2
    background-color: $gray-300
    border-radius: 4px

.add
  .select
    flex: 1
    margin-right: $margin-1_2
    margin-bottom: 0

.delete
  padding-left: $padding-1_2