@use "../../../../mixins" as *

.type,
.files,
.investor
  margin: 16px 0

.type,
.created,
.review,
.files,
.investor
  h2
    @apply font-medium text-lg mb-1

.files,
.investor
  ul
    padding-left: 16px

    li
      list-style: disc
      margin-bottom: 8px

      a
        color: $teal-700

        &:hover
          color: $teal-900

.investorDetails
  margin-bottom: 6px

  > div
    @include borderRadius
    @include padding-1_2
    display: inline-block
    background-color: $gray-200

.review
  margin-bottom: 8px

  form
    margin-top: -12px