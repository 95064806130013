@use "../../../../mixins" as *

.select
  select
    @include padding-1_2
    @include margin-top-1-1_2
    @include margin-bottom-1_2
    width: 100%
    display: block
    background-color: $gray-200

.investments
  @include margin-top

  strong
    color: $red-500

  ul
    li
      @include margin-left
      @include margin-top-1_4
      @include margin-bottom-1_4
      list-style: disc