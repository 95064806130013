@use "../../mixins" as *

.buttons
  display: flex
  flex-wrap: wrap

  > *
    @include margin-top-1_4
    @include margin-right-1_2
    @include margin-bottom-1_4

    &:last-child
      margin-right: 0

.complete
  color: $teal-700

.incomplete
  color: $red-700