@use "../../../mixins" as *

.download
  cursor: pointer
  > div
    @include padding-1_4
    cursor: pointer

  .icon
    width: 24px
    height: 24px

  &:hover
    .icon
      color: $teal-500