@use "../../../../../mixins" as *

.buttons
  display: flex
  justify-content: right
  margin-top: 8px

  button
    margin-right: 6px
    padding: 3px 12px

    &:last-child
      margin-right: 0

.noShareClassesAvailable
  @include borderRadius
  padding: 5px 8px
  background-color: $gray-200