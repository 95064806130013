.bankDetailsList > table {
    @apply relative mb-5;
    @apply text-sm;
}
.bankDetailsList > table::after {
    @apply w-full block absolute inset-x-0 pt-2.5;
    @apply border-b border-solid border-gray-200;
    content: "";
    top: 100%;
}
.bankDetailsList > table:last-child {
    @apply mb-0;
    @apply border-b-0;
}
.bankDetailsList > table:last-child::after {
    @apply hidden !important;
}

.bankDetails td {
    @apply border-b-0 !important;
}