@use "../../../mixins" as *

.pagination
  @include margin-top
  display: flex
  justify-content: space-between

  > div
    @include justifyVerticalCenter

.left
  @include fontSize14
  color: $gray-400

.right > div
  display: flex
  justify-content: flex-end

  > button
    @include borderRadius
    @include margin-left-1_4
    @include padding-1_4
    background-color: $gray-100

    &.active
      background-color: $gray-300

    svg
      width: 16px
      height: 16px