@use "../../../mixins" as *

.preview
  margin-bottom: 12px

  img
    width: auto

.icon,
.image
  display: flex
  justify-content: center

.documentIcon
  width: 65px
  height: 65px

.dimensions
  @include margin-top-1_2