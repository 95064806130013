@use "../../../mixins" as *

.sort
  @include padding-left-1_2
  @include padding-right-1_2
  display: flex
  flex: 1

  .icon
    @include padding-right-1_2

  .select
    flex: 1

  > div
    @include justifyVerticalCenter