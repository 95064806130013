.questions {
    @apply mt-8;
}

.questions h2 {
    @apply font-semibold text-lg;
}

.toggleResolve {
    @apply text-xs;
}
.toggleResolve > span {
    @apply text-gray-400 cursor-pointer;
}
.toggleResolve > span:hover {
    @apply underline;
}
