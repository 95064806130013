@use "../../../../mixins" as *

.search
  @include margin-bottom-3_4
  display: flex

  > div
    @include justifyVerticalCenter

    &:nth-child(2)
      flex: 1
      margin: 0 8px

  select,
  input
    margin-bottom: 0 !important

.card
  @include borderRadius
  text-align: center
  border: 4px solid $gray-300
  background-color: $gray-100

.select
  @include padding-bottom

  button
    @include fontSize14
    @include borderRadius
    @include padding-top-1_4
    @include padding-left-3_4
    @include padding-right-3_4
    @include padding-bottom-1_4
    font-weight: bold
    color: $white
    background-color: $teal-800

    &:hover
      background-color: $gray-600

@media screen and (min-width: 768px)
  .files
    @include gridTwoColumns

@media screen and (min-width: 1024px)
  .files
    @include gridThreeColumns