.wrapper
  margin-bottom: 12px

  label
    padding-bottom: 0

.editor
  border: 1px solid #E2E8F0
  border-radius: 6px

  ul, ol
    margin-left: 12px
    padding-left: 12px

  ol
    list-style: decimal

  ul
    list-style: disc

  a
    color: #2670D6
    text-decoration: underline

  p
    margin-bottom: 8px