.tableWrapper {
    @apply w-full overflow-x-scroll;
}

.table,
.tablePointer {
    @apply w-full border-collapse mt-3;
}

.table th,
.tablePointer th {
    @apply font-bold text-gray-500;
}

.table th,
.table td,
.tablePointer th,
.tablePointer td {
    @apply text-left px-3 py-2;
    @apply border-b border-solid border-gray-200;
}

.tablePointer tbody tr:hover td {
    @apply bg-gray-200;
}

.tablePointer tbody tr {
    @apply cursor-pointer;
}

.cellList {
    @apply text-xs
}
.cellList b {
    @apply font-semibold;
}
.cellList ul li {
    @apply mb-1;
}

.separatingHeadline {
    @apply text-xl mt-10 mb-2;
}