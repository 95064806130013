@use "../../mixins" as *

.overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, 0.4)
  z-index: 999

  .close
    cursor: pointer
    position: absolute
    top: 8px
    right: 8px

.modal
  position: fixed
  top: 50px
  left: 0
  right: 0
  bottom: 0
  overflow: scroll
  padding: 0 32px
  z-index: 1000

  > div
    @include borderRadius
    max-width: 1024px
    margin: 0 auto
    background-color: $white