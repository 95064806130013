@use "../../../../mixins" as *

.disabled
  @include margin-top-1-1_2
  @include margin-bottom-1-1_2
  color: $red-500

.buttons
  display: flex

  > *
    @include margin-right-1_2

    &:last-child
      margin-right: 0