@use "../../../../mixins" as *

.currentFile
  display: flex
  margin-bottom: 6px

  > div
    @include justifyVerticalCenter

.image
  padding-right: 8px

.text
  font-size: $font-size-14
  font-style: italic