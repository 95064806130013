@use "../../../mixins" as *

.box
  margin: $margin-1_2 0
  padding: $padding-1_2
  border: 2px solid $gray-300
  border-radius: 6px

.delete
  display: flex
  justify-content: flex-end

  button
    font-size: 14px
    color: $gray-500
    padding: $padding-1_4 $padding-1_2
    background-color: $gray-300
    border-radius: 4px