@use "../../../mixins" as *

.type
  display: flex

  button
    font-weight: bold
    color: white
    width: 100%
    display: block
    padding: 8px 16px
    background-color: $gray-400

    &.active
      background-color: $teal-500

    &:first-child
      @include borderRadius-top-left

    &:last-child
      @include borderRadius-top-right

.content
  @include borderRadius-bottom
  padding: 12px 16px
  margin-bottom: 24px
  border-left: 2px solid $gray-400
  border-right: 2px solid $gray-400
  border-bottom: 2px solid $gray-400

  > div
    &.hidden
      display: none