.dashboard {
    @apply grid grid-cols-1 gap-4;
    @apply pt-2;
}

.box > div {
    @apply p-4;
    @apply bg-gray-100 border border-gray-300 rounded;
}
.boxRed {
    @apply box;
}
.boxRed > div {
    @apply bg-red-100 border border-red-300;
}

.box h3 {
    @apply text-xl font-bold;
    @apply mb-3;
}
.boxRed h3 {
    @apply text-red-500;
}

.box a {
    @apply block pt-3;
}
.box a:hover {
    @apply underline;
}

.box ul {
    @apply pt-2 pl-4;
}
.box li {
    @apply list-disc;
}
.box li a {
    @apply pt-1;
}

@media screen and (min-width: 768px) {
    .dashboard {
        @apply grid-cols-2;
    }
}

@media screen and (min-width: 1280px) {
    .dashboard {
        @apply grid-cols-3;
    }
}
