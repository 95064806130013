@use "../../../mixins" as *

.investment
  margin-bottom: 12px

  ul
    @include list

  > div
    font-size: 0.875rem
    font-weight: 500
    text-transform: uppercase

  a
    color: $teal-800