@use "../../../mixins" as *

.upload
  margin-bottom: 12px

.head
  display: flex

  > div
    @include padding-3_4
    cursor: pointer
    font-weight: bold
    color: $gray-600
    text-align: center
    background-color: $gray-200

    &:first-child
      @include borderRadius-top-left
      flex: 1

    &:last-child
      @include borderRadius-top-right
      flex: 1

    &:hover,
    &.active
      color: $white
      background-color: $teal-500

.body
  @include padding