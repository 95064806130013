.meta
  font-size: 12px
  display: flex

.metaIndex
  flex: 1

.valuationMeta
  font-size: 12px
  text-align: right
  display: flex
  justify-content: flex-end