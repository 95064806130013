.form {
    @apply pt-4;
}

.form label,
.form .label {
    @apply block pb-3;
}

.form label > span,
.form .label > span {
    @apply uppercase font-medium text-sm;
}

.form label span small {
    text-transform: none;
}

.form input[type=date],
.form input[type=datetime-local],
.form input[type=text],
.form input[type=number],
.form select,
.form textarea {
    @apply w-full px-4 py-2 mb-1;
    @apply rounded-md bg-slate-100;
    @apply border border-slate-200;
}
.form select {
    @apply px-3;
}
.form textarea {
    @apply mb-0;
}

.form h3 {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 1px;
}

.checkbox {
    @apply pb-2;
}
.checkboxInner {
    @apply flex pb-1;
}
.checkbox label {
    @apply text-base pb-0 pl-2;
}

.submitButton[type=submit] {
    @apply px-4 py-2 mt-5 rounded;
    @apply font-medium;
    @apply bg-teal-500 text-white;
    transition: background .15s ease-in-out;
}
.submitButton[type=submit]:hover {
    @apply bg-teal-600;
}

.error {
    @apply block text-sm text-red-500;
}