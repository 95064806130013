.layout {
    @apply flex flex-wrap w-full;
}

.wrapper {
    background-color: rgba(240, 244, 247, 0.5);
}

.content {
    @apply px-6 py-5;
}

@media screen and (max-width: 1023px) {
    .wrapper {
        @apply w-full min-h-screen;
    }
}

@media screen and (min-width: 1024px) {
    .wrapper {
        width: calc(100% - 240px);
    }
}