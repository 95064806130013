.header {
    @apply flex flex-wrap;
    @apply bg-white;
    box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 3px -1px;
}

.headerLeft {
    @apply w-1/2 px-6;
    @apply flex flex-col justify-center;
}

.headerRight {
    @apply w-1/2 text-right;
}

.logout {
    @apply inline-block px-5 py-3;
    @apply text-white bg-red-500;
    transition: background .15s ease-in-out;
}
.logout:hover {
    @apply bg-red-700;
}


@media screen and (max-width: 1023px) {
    .sidebarToggle {
        @apply w-7 h-7;
    }

    .email {
        @apply hidden;
    }
}

@media screen and (min-width: 1024px) {
    .sidebarToggle {
        @apply hidden;
    }

    .email {
        @apply inline-block pr-3;
    }
}