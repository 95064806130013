.upload {
    @apply mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300;
}
.upload > div {
    @apply px-6 pt-5 pb-6;
}
.upload svg {
    @apply mx-auto h-12 w-12 text-gray-400;
}

.labelButton {
    @apply relative cursor-pointer rounded-md bg-transparent font-medium;
    @apply focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500;
}

.uploadFile {
    @apply text-sm text-center font-medium;
    @apply bg-gray-500 rounded-md mt-1;
    @apply flex py-1;
}

.fileIdentifier {
    @apply flex flex-col justify-center;
    @apply flex-1 pl-4;
}

.operation {
    @apply flex flex-col justify-center px-2.5 py-0.5 ml-2;
}

.loader {
    @apply flex flex-col justify-center h-5;
}