@use "../../../mixins" as *

.list
  @include list

.miscellaneous
  @include margin-bottom

.dealLinkPublic
  @include fontSize12
  @include margin-bottom
  text-align: left
  margin-top: -8px

  &:hover
    text-decoration: underline

.assignedCompany
  margin-top: 6px

.eventHead
  display: flex
  margin-top: 20px

  h3
    margin: 0

  > div
    @include justifyVerticalCenter

    &:first-child
      flex: 1

.paymentInstructionsManuallyTriggered
  margin-bottom: 20px

.publiclyAvailableDataPointsSelectAll
  @include fontSize12
  color: $teal-700
  text-align: right
  margin-top: 3px