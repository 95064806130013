@use "../../../mixins" as *

.filter
  @include padding-left-1_2
  @include padding-right-1_2
  display: flex
  flex: 1

  .icon
    @include padding-right-1_2

  .value
    @include padding-left-1_2
    @include padding-right-1_2
    flex: 1

  button,
  input
    padding: 3px 5px

  .button
    button
      @include padding-left-1_2
      @include padding-right-1_2
      color: $white
      background-color: $teal-800

  > div
    @include justifyVerticalCenter