@use "../../mixins" as *

.buttons
  display: flex

  > *
    @include margin-right-1_2

    &:last-child
      margin-right: 0

.memberAndInvitations
  text-align: right

  a
    color: $teal-500

    &:hover
      text-decoration: underline