@use "../../../mixins" as *

.preview
  @include margin-top
  @include margin-bottom
  display: flex
  justify-content: center

  > div
    @include borderRadius
    @include padding-top-3_4
    @include padding-left-3_4
    @include padding-right-3_4
    background-color: $gray-200