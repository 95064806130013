@use "../../mixins" as *

.section
  @include padding
  background-color: $white
  box-shadow: 0 2px 5px -1px rgb(50 50 93 / 2%), 0 1px 3px -1px rgb(0 0 0 / 5%)

.sectionHeading
  display: flex
  flex-wrap: wrap
  @include margin-bottom

  h3
    @include fontSize24
    @include fontWeightMedium

  h4
    @include fontSize14
    color: $gray-400

.sectionHeadingLeft
  @include justifyVerticalCenter
  flex: 1

.sectionHeadingRight
  @include justifyVerticalCenter