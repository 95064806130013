@use "../../mixins" as *

.tile
  @include borderRadius
  padding: 8px
  margin-bottom: 12px
  border: 2px solid $gray-300

.actions
  display: flex
  justify-content: flex-end

  button
    @include borderRadius
    padding: 2px 5px
    background-color: $gray-300

.add
  margin-bottom: 8px