@use "../../../mixins" as *

.file
  @include borderRadius
  text-align: center
  border: 4px solid $gray-300
  background-color: $gray-100

  &:hover
    background-color: $gray-200

.card
  cursor: pointer

.actions
  @include padding-3_4

  &.multiple
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))

  > div
    display: flex
    justify-content: center

@media screen and (min-width: 768px)
  .files
    @include gridTwoColumns

@media screen and (min-width: 1024px)
  .files
    @include gridThreeColumns

@media screen and (min-width: 1280px)
  .files
    @include gridFourColumns