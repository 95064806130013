@use "../../../../mixins" as *

.card
  @include padding-3_4

.id
  &.small
    @include fontSize12

.identifier
  margin-bottom: 4px

.type
  @include borderRadius
  @include fontSize14
  font-weight: bold
  color: white
  padding: 2px 8px
  margin-top: 12px
  background-color: $gray-400