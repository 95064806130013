.alert
    padding: 12px 20px
    margin-bottom: 15px
    border: 1px solid
    border-radius: 4px

    &.noMargin
        margin-bottom: 0

.alertSuccess
    color: #155724
    border-color: #c3e6cb
    background-color: #d4edda

.alertInfo
    color: #0c5460
    border-color: #bee5eb
    background-color: #d1ecf1

.alertWarning
    color: #856404
    border-color: #ffeeba
    background-color: #fff3cd

.alertError
    color: #721c24
    border-color: #f5c6cb
    background-color: #f8d7da