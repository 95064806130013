@use "../../../../mixins" as *

.loading
  font-size: $font-size-14
  color: $gray-600

  > div:last-child
    margin-top: 3px

.identifier
  @include margin-top

.uploadButton
  @include margin-top_3_4