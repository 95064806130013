$red-500: #EF4444
$red-700: #b91c1c
$gray-100: #F3F4F6
$gray-200: #E5E7EB
$gray-300: #D1D5DB
$gray-400: #9CA3AF
$gray-500: #6B7280
$gray-600: #4b5563
$teal-500: #14B8A6
$teal-700: #0f766e
$teal-800: #115E59
$teal-900: #134e4a
$white: #FFFFFF

$font-size-12: 12px
$font-size-14: 14px
$font-size-16: 16px
$font-size-24: 24px

$margin: 16px
$margin-3_4: 12px
$margin-1_2: 8px
$margin-1_4: 4px

$padding: 16px
$padding-3_4: 12px
$padding-1_2: 8px
$padding-1_4: 4px
