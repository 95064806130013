@use "../../mixins" as *

.button
    @include borderRadius
    @include padding-top-1_2
    @include padding-bottom-1_2
    @include padding-left
    @include padding-right
    display: inline-flex
    font-weight: 600
    text-align: center
    align-items: center
    @apply inline-flex items-center
    transition: background .15s ease-in-out

.disabled
    cursor: not-allowed !important

.small
    @include fontSize12
    @include padding-top-1_4
    @include padding-bottom-1_4
    @include padding-left-1_2
    @include padding-right-1_2

.buttonPrimary
    color: $white
    background-color: $teal-700

    &:hover
        background-color: $teal-800

.buttonSecondary
    color: #31353F
    background-color: $gray-400

    &:hover
        color: $white
        background-color: $teal-800

.buttonDelete
    color: $white
    background-color: $red-500

    &:hover
        background-color: $red-700